import React, { FC, MouseEventHandler } from 'react'

import { LinariaClassName, css, cx } from '@linaria/core'
import { Link } from 'react-router-dom'

import { WithChildren, WithClassName, WithDataName } from 'common/types'

import { ButtonInner } from './ButtonInner'
import { Color, Size } from './types.common'
import { Anchor } from '../Link/Anchor'
import { formaDJRCyrillicMicroFontFamily } from '../shared/fonts/formaDJRCyrillic/formaDJRCyrillicFontFamily'

export const GhostButton: FC<
  {
    to?: string
    href?: string
    loading?: boolean
    size?: Size
    color?: Color
    disabled?: boolean
    onClick?: MouseEventHandler<HTMLElement>
  } & WithChildren &
    WithDataName &
    WithClassName
> = ({
  className,
  to,
  href,
  children,
  loading,
  size = 'L',
  color = 'accent',
  disabled,
  ...restProps
}) => {
  const classNames = cx(
    ghostButtonCss,
    buttonSizesMapCss[size],
    colorsMap[color],
    loading ? loadingCss : statesCss
  )

  const _disabled = loading || disabled

  const innerElement = (
    <ButtonInner size={size} loading={loading}>
      {children}
    </ButtonInner>
  )

  if (to) {
    return (
      <div className={className}>
        <Link to={to} className={classNames} {...restProps}>
          {innerElement}
        </Link>
      </div>
    )
  }

  if (href) {
    return (
      <div className={className}>
        <Anchor href={href} className={classNames} {...restProps}>
          {innerElement}
        </Anchor>
      </div>
    )
  }

  return (
    <div className={className}>
      <button
        type="button"
        className={classNames}
        disabled={_disabled}
        {...restProps}
      >
        {innerElement}
      </button>
    </div>
  )
}

const ghostButtonCss = css`
  width: 100%;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  justify-content: center;
  align-items: center;
  appearance: none;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-family: ${formaDJRCyrillicMicroFontFamily};
  color: var(--accent-primary-default);
  background-color: transparent;
`

const buttonSizesMapCss: Record<Size, LinariaClassName> = {
  L: css`
    padding: var(--spacing-14px) var(--spacing-24px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  M: css`
    padding: var(--spacing-12px) var(--spacing-18px);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.32px;
  `,
  S: css`
    padding: var(--spacing-8px) var(--spacing-14px);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.28px;
  `,
}

const statesCss = css`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: var(--accent-primary-hover);
    }
  }

  &:active {
    color: var(--accent-primary-press);
  }

  &:disabled {
    color: var(--foreground-disabled);
    cursor: not-allowed;
  }
`

const colorsMap: Record<Color, LinariaClassName> = {
  accent: css`
    --accent-primary-default: #2e2a29;
    --accent-primary-hover: #5c504b;
    --accent-primary-press: #7b6b64;

    circle {
      fill: var(--accent-primary-default);
    }
  `,
  white: css`
    --accent-primary-default: var(--static-white);
    --accent-primary-hover: #e7deff;
    --accent-primary-press: #cebbff;

    circle {
      fill: var(--static-white);
    }
  `,
  mamba: css`
    --accent-primary-default: #ff8640;
    --accent-primary-hover: #f07e3c;
    --accent-primary-press: #e07638;

    circle {
      fill: var(--static-accent);
    }
  `,
}

const loadingCss = css`
  cursor: progress;
`
