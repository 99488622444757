import React, { ComponentPropsWithoutRef, forwardRef } from 'react'

import { css } from '@linaria/core'
import { styled } from '@linaria/react'

export const Anchor = forwardRef<
  HTMLAnchorElement,
  { black?: boolean } & ComponentPropsWithoutRef<typeof StyledAnchor>
>(({ href, children, black, ...props }, ref) => {
  return (
    <StyledAnchor
      ref={ref}
      className={black ? blackCss : blueCss}
      href={href}
      {...props}
    >
      {children}
    </StyledAnchor>
  )
})

const StyledAnchor = styled.a`
  text-decoration: none;
  cursor: pointer;
`
const blueCss = css`
  color: var(--info-soft);

  &:visited {
    color: var(--info-soft);
  }
`
const blackCss = css`
  color: var(--accent-primary-default);

  &:visited {
    color: var(--accent-primary-default);
  }
`
